import React from 'react';
import ReactDOM from 'react-dom';
import ChatBubble, { useConvaiClient } from "convai-chatui-sdk";

function App() {
  // Function to get UTM parameters
  const getUTMParameter = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  // Extract CharacterID from URL
  const personaID = getUTMParameter('personaID') || "a8199d8e-890c-11ee-a1bf-42010a40000e"; // Replace "default-character-id" with a default ID if needed

  const { client } = useConvaiClient(personaID, "3b4167d0a4e8b2383a8d1f8e63fc9ca8"); // Use your actual API key here

  return (
    <ChatBubble
      chatHistory="Show"
      chatUiVariant="Expanded Side Chat"
      client={client}
    />
  );
}

ReactDOM.render(<App />, document.getElementById('chat-container'));